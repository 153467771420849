import {
  BoundNumberField,
  BoundSelectField,
  BoundTextField,
  Button,
  column,
  emptyCell,
  GridDataRow,
  GridTable,
  IconButton,
  simpleHeader,
  TextField,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useHistory, useParams } from "react-router";
import { Price } from "src/components";
import { PdfViewer } from "src/components/PdfViewer";
import {
  AssetInfoFragment,
  InputMaybe,
  LotAcqLotAcqSettlementStatementFragment,
  LotAcqLotAcqSettlementStatementLineItemFragment,
  LotAcqLotFragment,
  LotAcqSettlementStatementLineItemType,
  Maybe,
  SaveLotAcqSettlementStatementInput,
  useDeleteLotAcqSettlementStatementMutation,
  useLotAcqSettlementStatementDocumentsQuery,
  useProcessLotAcqSettlementStatementOcrMutation,
  useSaveLotAcqSettlementStatementMutation,
  useSendLotAcqSettlementStatementForBookingMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DocumentIdParams } from "src/routes/routesDef";
import { createLotDetailsUrl } from "src/RouteUrls";
import { queryResult } from "src/utils/queryResult";
import { safeEntries } from "src/utils/utils";

export function LotAcqSettlementStatementEditor() {
  const { documentId } = useParams<DocumentIdParams>();

  const documentsQuery = useLotAcqSettlementStatementDocumentsQuery({
    variables: { documentIds: [documentId] },
    skip: !documentId,
  });

  return queryResult(documentsQuery, (data) => {
    const document = data.documents[0];
    const lot = document.lot;
    const documentId = document.id;
    const lass = document.lotAcqSettlementStatement;
    // If it's new, it doesn't have a LASS to this document yet
    if (!lass) {
      return <LotAcqSettlementStatementForm documentId={documentId} lot={lot} assetInfo={document.asset} />;
    }
    // Otherwise it does
    return (
      <LotAcqSettlementStatementForm
        documentId={documentId}
        lot={lot}
        assetInfo={document.asset}
        lotAcqSettlementStatement={lass}
      />
    );
  });
}

type CreateLotAcqSettlementStatementEditorProps = {
  documentId?: string;
  lot?: LotAcqLotFragment;
  assetInfo?: AssetInfoFragment;
  lotAcqSettlementStatement?: LotAcqLotAcqSettlementStatementFragment;
};

function LotAcqSettlementStatementForm({
  documentId,
  lot,
  assetInfo,
  lotAcqSettlementStatement,
}: CreateLotAcqSettlementStatementEditorProps) {
  const settlementStatement = lotAcqSettlementStatement;
  const [saveLotAcqSettlementStatement] = useSaveLotAcqSettlementStatementMutation();
  const [deleteLotAcqSettlementStatement] = useDeleteLotAcqSettlementStatementMutation();
  const [processLotAcqSettlementStatementOcr] = useProcessLotAcqSettlementStatementOcrMutation();
  const [sendLotAcqSettlementStatementForBooking] = useSendLotAcqSettlementStatementForBookingMutation();
  const history = useHistory();

  async function saveLotAcqSettlementStatementFunction(formState: ObjectState<LotAcqSettlementstatementFormInput>) {
    await saveLotAcqSettlementStatement({
      variables: {
        input: mapToInput(formState.value),
      },
    });
    // If we are saving this for the first time, just redirect to the same page and start afresh - makes the handling much easier
    if (!lotAcqSettlementStatement) {
      window.location.reload();
    }
  }

  const formState = useFormState({
    config: formConfig,
    init: {
      input: settlementStatement ? mapSettlementStatementToForm(settlementStatement) : { documentId, lotId: lot?.id },
    },
    autoSave: saveLotAcqSettlementStatementFunction,
  });

  function mapToInput(formStateValue: LotAcqSettlementstatementFormInput): SaveLotAcqSettlementStatementInput {
    const { documentId, lotId, ...others } = formStateValue;
    return {
      ...others,
      documentId,
      lotId,
      lineItems:
        formStateValue.lineItems?.map((li) => {
          return {
            id: li.id,
            amountInCents: li.amountInCents,
            type: li.type,
            notes: li.notes,
          };
        }) || [],
    };
  }

  const title = lotAcqSettlementStatement
    ? "Edit Settlement Statement for " + lot?.address?.street1 + " (" + lot?.id + ")"
    : "Create Settlement Statement for " + lot?.address?.street1 + " (" + lot?.id + ")";

  function createColumns(formState: ObjectState<LotAcqSettlementstatementFormInput>) {
    const typeColumn = column<Row>({
      header: () => "Type",
      lineItem: (row) => {
        const formStateField = formState.lineItems.rows.find((li) => li.id.value === row.id);
        const thisColumn = {
          value: () => row.type,
          content: () => (
            <BoundSelectField
              field={formStateField!.type}
              options={safeEntries(LotAcqSettlementStatementLineItemType)}
              getOptionValue={([, code]) => code}
              getOptionLabel={([name]) => name}
            />
          ),
        };
        return thisColumn;
      },
      addLineItem: emptyCell,
    });
    const amountColumn = column<Row>({
      header: () => "Amount",
      lineItem: (row) => {
        const formStateField = formState.lineItems.rows.find((li) => li.id.value === row.id);
        const thisColumn = {
          value: () => row.amountInCents,
          content: () => <BoundNumberField type="cents" field={formStateField!.amountInCents} />,
          tooltip: "The amount of the line item",
        };
        return thisColumn;
      },
      w: "200px",
      addLineItem: emptyCell,
    });

    const notesColumn = column<Row>({
      header: () => "Notes",
      lineItem: (row) => {
        const formStateField = formState.lineItems.rows.find((lp) => lp.id.value === row.id);
        return {
          value: () => row.notes,
          content: () => <BoundTextField field={formStateField!.notes} />,
        };
      },
      w: "600px",
      addLineItem: emptyCell,
    });
    return [typeColumn, amountColumn, notesColumn];
  }

  function createRows(lineItems: LotAcqLotAcqSettlementStatementLineItemFragment[] | undefined): GridDataRow<Row>[] {
    const lineItemRows =
      lineItems?.map((li) => {
        const row: LineItemRow = { kind: "lineItem", id: li.id, data: li };
        return row;
      }) || [];
    const addRow: AddLineItemRow = { kind: "addLineItem" as const, id: "add", data: undefined };
    return [simpleHeader, ...lineItemRows, addRow];
  }

  const bookingTooltip = settlementStatement?.canSendForBooking.allowed
    ? "Books the Settlement Statement to Accounting - cannot be undone"
    : settlementStatement?.canSendForBooking.disabledReasons.map((reason) => reason.message).join(", ");

  const isBooked = settlementStatement?.bookingEventSent;

  return (
    <>
      <PageHeader title={title} />
      <TextField
        label="Investment Status"
        value={settlementStatement?.lot.lotInvestmentStatus.name}
        readOnly
        onChange={() => {}}
      />
      <TextField
        label="Legal Entity Acquiring the Lot"
        value={settlementStatement?.lot.legalEntityName || ""}
        readOnly
        onChange={() => {}}
      />
      <BoundTextField field={formState.buyerName} label="Buyer Name (from this document)" readOnly={isBooked} />
      Total Debits:
      <Price valueInCents={lotAcqSettlementStatement?.totalDebitsInCents} dropZero />
      Total Credits:
      <Price valueInCents={lotAcqSettlementStatement?.totalCreditsInCents} dropZero />
      <GridTable columns={createColumns(formState)} rows={createRows(lotAcqSettlementStatement?.lineItems)} />
      <Button
        variant="secondary"
        label="Add Line Item"
        onClick={async () => {
          formState.lineItems.add({
            id: undefined,
            amountInCents: 0,
            type: LotAcqSettlementStatementLineItemType.Other,
            notes: "",
          });
          formState.commitChanges();
        }}
        disabled={isBooked}
      />
      <Button
        variant="secondary"
        label="Reprocess OCR"
        onClick={async () => {
          await processLotAcqSettlementStatementOcr({ variables: { input: { id: settlementStatement!.id } } });
          window.location.reload();
        }}
        disabled={isBooked}
      />
      <Button
        variant="secondary"
        label="Book To Accounting"
        onClick={async () => {
          // Send to booking
          await sendLotAcqSettlementStatementForBooking({ variables: { input: { id: settlementStatement!.id } } });
          // Then send them back to the lot
          history.push({ pathname: createLotDetailsUrl(settlementStatement!.lot.id) });
        }}
        disabled={!settlementStatement?.canSendForBooking.allowed}
        tooltip={bookingTooltip}
      />
      <IconButton
        icon="trash"
        tooltip="delete Lot Acq Settlement Statement"
        onClick={async () => {
          await deleteLotAcqSettlementStatement({
            variables: {
              input: { id: settlementStatement!.id },
            },
          });
          history.push({ pathname: createLotDetailsUrl(settlementStatement!.lot.id) });
        }}
        disabled={isBooked}
      />
      <PdfViewer
        hasHeader
        title={"Uploaded Settlement Statement PDF"}
        assets={[assetInfo]}
        pdfPageWidth={getDynamicPdfVierwerWidth()}
      />
    </>
  );
}

export type LotAcqSettlementstatementFormInput = {
  id: InputMaybe<string>;
  lotId: InputMaybe<string>;
  documentId: InputMaybe<string>;
  buyerName: InputMaybe<string>;
  lineItems: {
    id: InputMaybe<string>;
    type: InputMaybe<LotAcqSettlementStatementLineItemType>;
    amountInCents: InputMaybe<number>;
    notes: InputMaybe<string>;
  }[];
};

export const formConfig: ObjectConfig<LotAcqSettlementstatementFormInput> = {
  id: { type: "value" },
  lotId: { type: "value", rules: [required] },
  documentId: { type: "value", rules: [required] },
  buyerName: { type: "value", rules: [required] },
  lineItems: {
    type: "list",
    config: {
      id: { type: "value" },
      type: { type: "value" },
      amountInCents: { type: "value" },
      notes: { type: "value" },
    },
  },
};

function mapSettlementStatementToForm(
  settlementStatement: LotAcqLotAcqSettlementStatementFragment,
): LotAcqSettlementstatementFormInput {
  return {
    id: settlementStatement.id,
    lotId: settlementStatement.lot.id,
    documentId: settlementStatement.document.id,
    buyerName: settlementStatement.buyerName,
    lineItems: mapLotPartitionsToForm(settlementStatement.lineItems),
  };
}

export function mapLotPartitionsToForm(lineItems: Maybe<LotAcqLotAcqSettlementStatementLineItemFragment[]>) {
  const lines = lineItems?.map((li) => {
    return {
      id: li.id,
      amountInCents: li.amountInCents,
      type: li.type.code,
      notes: li.notes,
    };
  });
  return lines || [];
}

export function getDynamicPdfVierwerWidth(browserWidth?: number) {
  const { innerHeight } = window;
  return innerHeight > 900 ? 820 : innerHeight > 800 ? 760 : 640;
}

type HeaderRow = { kind: "header" };
type AddLineItemRow = { kind: "addLineItem"; id: string; data: undefined };
type LineItemRow = { kind: "lineItem"; id: string; data: LotAcqLotAcqSettlementStatementLineItemFragment };
type Row = AddLineItemRow | HeaderRow | LineItemRow;

import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { FundLoanManagementPageFundFragment } from "src/generated/graphql-types";
import { FundDrawsTable } from "./FundDrawsTable";
import { FundInvoicesTable } from "./FundInvoicesTable";
import { FundPaymentsTable } from "./FundPaymentsTable";
import { LoanCommitmentsTable } from "./LoanCommitmentsTable";
import { FundDrawRequestsTable } from "./FundDrawRequestsTable";
import { LoanInterestSummaryTable } from "./LoanInterestSummaryTable";
import { LotPartitionAppraisalsTable } from "./LotPartitionAppraisalsTable";
import { LoanInterestByMonthTable } from "./LoanInterestByMonthTable";

export type FundLoanManagementTabsProps = {
  fund: FundLoanManagementPageFundFragment;
};

export function FundLoanManagementTabs({ fund }: FundLoanManagementTabsProps) {
  const [currentTab, setCurrentTab] = useState("Draws");

  const tabs = getTabs({ fund });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: FundLoanManagementTabsProps) {
  const { fund } = props;
  const { id } = fund;

  return [
    {
      name: "Appraisals",
      value: "Appraisals",
      render: () => <LotPartitionAppraisalsTable fund={fund} />,
    },
    {
      name: "Loan Commitment Summary",
      value: "LoanCommitmentSummary",
      render: () => <LoanCommitmentsTable fundId={id} lots={fund.lots} />,
    },
    {
      name: "Draw Requests",
      value: "DrawRequests",
      render: () => <FundDrawRequestsTable fundId={id} />,
    },
    {
      name: "Draws",
      value: "Draws",
      render: () => <FundDrawsTable draws={fund.creditFacilityDraws} fundId={id} />,
    },
    {
      name: "Payments",
      value: "Payments",
      render: () => <FundPaymentsTable payments={fund.creditFacilityPayments} fundId={id} />,
    },
    {
      name: "Invoices",
      value: "Invoices",
      render: () => <FundInvoicesTable invoices={fund.creditFacilityInvoices} fundId={id} />,
    },
    {
      name: "Loan Interest Summary",
      value: "Loan Interest Summary",
      render: () => <LoanInterestSummaryTable fundId={id} lots={fund.lots} />,
    },
    {
      name: "Loan Interest By Month",
      value: "Loan Interest By Month",
      render: () => <LoanInterestByMonthTable fundId={id} lots={fund.lots} />,
    },
  ];
}

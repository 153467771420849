import { AuthViewProps, HomeboundUser, signOut } from "@homebound/auth-components";
import { ButtonMenu, Icon, NavLink, Palette } from "@homebound/beam";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HomeboundIcon } from "src/components/HomeboundLogo";
import { Css, px } from "src/Css";
import { useTestIds } from "src/hooks";
import { fundAllocationsPath, fundsPath, lotsPath, metrosPath, underwritingDefaultsPath } from "src/routes/routesDef";

type GlobalNavProps = Pick<AuthViewProps, "user">;

export function GlobalNav({ user }: GlobalNavProps) {
  const location = useLocation();
  const [navId, navLinkId, homeLinkId] = useTestIds("globalNav", ["link", "helpLink", "homeLink"]);

  const toolbarLinks: {
    // What is shown as a label
    label: string;
    // Which path or paths will activate the label
    path: string | string[];
    // What path to redirect the user when clicking on the label
    value?: string;
  }[] = [
    { label: "Lots", path: lotsPath, value: lotsPath },
    { label: "Funds", path: fundsPath, value: fundsPath },
    { label: "Metros", path: metrosPath, value: metrosPath },
    { label: "Fund Allocations", path: fundAllocationsPath, value: fundAllocationsPath },
    { label: "Underwriting", path: underwritingDefaultsPath, value: underwritingDefaultsPath },
  ];

  return (
    <nav css={Css.bgBlack.fs0.w100.sticky.top0.z999.h(px(topNavHeight)).df.aic.$} {...navId}>
      <div css={Css.mx3.$}>
        <Link to={lotsPath} {...homeLinkId}>
          <HomeboundIcon width={45} color={Palette.White} />
        </Link>
      </div>
      <div css={Css.df.aic.mx1.fg1.gap2.$}>
        {toolbarLinks.map(({ label, path, value = Array.isArray(path) ? path[0] : path }) => (
          <NavLink
            key={value}
            href={value}
            label={label}
            active={
              Array.isArray(path)
                ? path.some((p) => location.pathname.startsWith(p))
                : location.pathname.startsWith(path)
            }
            variant="global"
            {...navLinkId}
          />
        ))}
      </div>
      <div>
        <Link to="/status">
          <Icon icon="infoCircle" />
        </Link>
      </div>
      <div css={Css.ml2.mr3.$}>
        <ProfileMenu user={user} />
      </div>
    </nav>
  );
}

type ProfileMenuProps = {
  user?: HomeboundUser;
};

function ProfileMenu({ user }: ProfileMenuProps) {
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuIsOpen = Boolean(menuAnchor);

  function handleMenuOpen(event: any) {
    setMenuAnchor(event.currentTarget);
  }

  return (
    <>
      {user?.name && (
        <ButtonMenu
          items={[]}
          trigger={{ src: user.picture, name: user.name }}
          placement="right"
          persistentItems={[
            {
              label: "Profile",
              onClick: () => {
                window.open("https://myaccount.google.com/personal-info", "_blank");
              },
            },
            {
              label: "Sign Out",
              onClick: () => {
                signOut().catch(console.error);
              },
            },
          ]}
        />
      )}
    </>
  );
}

export const topNavHeight = 48;

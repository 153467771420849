import { Css, ScrollableContent } from "@homebound/beam";
import { PageHeader } from "src/routes/layout/PageHeader";
import { queryResult } from "src/utils/queryResult";
import { useUnderwritingOverviewUnderwritingConfigQuery } from "src/generated/graphql-types";
import { UnderwritingOverviewTabs } from "./UnderwritingOverviewTabs";

export function UnderwritingOverviewPage() {
  const underwritingConfigId = "uc:1"; // We are only using one underwriting config at this point
  const query = useUnderwritingOverviewUnderwritingConfigQuery({ variables: { underwritingConfigId } });

  return queryResult(query, {
    data: (data) => {
      const { underwritingConfigs } = data;
      const underwritingConfig = underwritingConfigs[0];
      const title = `Underwriting Default Configurations`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="underwritingOverviewPage">
              <div css={Css.fg1.$}>
                <UnderwritingOverviewTabs underwritingConfig={underwritingConfig} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}

import {
  Button,
  column,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useGridTableApi,
} from "@homebound/beam";
import { endOfMonth, isEqual } from "date-fns";
import { priceCellContent } from "src/components";
import {
  FundLoanManagementPageLotFragment,
  FundLoanManagementPageLotPartitionFragment,
} from "src/generated/graphql-types";
import { DateOnly, formatYearFirst } from "src/utils/dates";

export type LoanInterestByMonthTableProps = {
  lots: FundLoanManagementPageLotFragment[];
  fundId: string;
};

export function LoanInterestByMonthTable(props: LoanInterestByMonthTableProps) {
  const { lots } = props;
  const lotPartitions = (lots.flatMap((lot) => lot.partitions) || []).compact();
  const tableApi = useGridTableApi<Row>();

  // Get the end of each of the months here
  const endOfAllInvoiceMonths = lots
    .flatMap((lot) => [
      ...lot.partitions.flatMap((partition) =>
        partition.draws.flatMap((draw) =>
          draw.invoices.flatMap((invoice) => new DateOnly(endOfMonth(invoice.creditFacilityInvoice.invoiceDate))),
        ),
      ),
    ])
    .sort()
    .compact()
    .filter((date, index, array) => !isEqual(date, array[index - 1])); // remove duplicates, for some reason .unique() doesn't work here, probably something around isEquals vs ===

  return (
    <>
      <GridTable
        id="loanInterestByMonthTable"
        rowStyles={rowStyles}
        columns={createColumns(endOfAllInvoiceMonths)}
        rows={createLotRows(lotPartitions)}
        fallbackMessage="Interest invoiced by month for lot partitions will appear here."
        sorting={{ on: "client" }}
        stickyHeader
        api={tableApi}
      />
      <Button onClick={() => tableApi.downloadToCsv("LoanInterestByMonth.csv")} label="Download CSV" />
    </>
  );
}

type Row = SimpleHeaderAndData<FundLoanManagementPageLotPartitionFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotRows(lotPartitions: FundLoanManagementPageLotPartitionFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitions);
}

function createSharedColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Lot Partition ID",
    data: ({ id }) => id,
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: () => "Lot Address",
    data: ({ lot }) => lot.address?.street1,
    sticky: "left",
  });

  const blueprintIdColumn = column<Row>({
    header: () => "Blueprint Id",
    data: ({ blueprintProjectId }) => blueprintProjectId,
    sticky: "left",
  });

  return [idColumn, addressColumn, blueprintIdColumn];
}

function createColumns(endOfAllInvoiceMonths: DateOnly[]): GridColumn<Row>[] {
  const sharedColumns = createSharedColumns();
  const monthlyDateColumns = endOfAllInvoiceMonths.map((monthlyDate) => {
    return column<Row>({
      header: () => formatYearFirst(monthlyDate),
      data: (row) => amountSpentGridCellContent(row, monthlyDate),
      w: "100px",
    });
  });

  return [...sharedColumns, ...monthlyDateColumns];
}

function amountSpentGridCellContent(
  row: FundLoanManagementPageLotPartitionFragment,
  monthlyDate: DateOnly,
): GridCellContent {
  const totalAmountInvoiced = interestAmountInvoiced(row, monthlyDate);
  return {
    alignment: "left",
    content: priceCellContent(totalAmountInvoiced),
    value: totalAmountInvoiced,
  };
}

function interestAmountInvoiced(row: FundLoanManagementPageLotPartitionFragment, monthlyDate: DateOnly): number {
  const interestAmountForMonth = row.draws
    .flatMap((draw) => draw.invoices)
    .filter((invoice) => isEqual(new DateOnly(endOfMonth(invoice.creditFacilityInvoice.invoiceDate)), monthlyDate))
    .map((invoice) => invoice.interestAmountInCents)
    .sum();
  return interestAmountForMonth ?? 0;
}

/* eslint-disable no-empty-pattern */
import {
  BoundSelectField,
  Button,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  ScrollableContent,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { useMemo } from "react";
import {
  FundAllocationsPageFundFragment,
  FundAllocationsPageLotFragment,
  Maybe,
  SaveLotInput,
  SaveLotsInput,
  useFundAllocationsPageFundsQuery,
  useFundAllocationsPageLotsQuery,
  useSaveLotsMutation,
  useSendCustomerInformationToBlueprintMutation,
} from "src/generated/graphql-types";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { queryResult } from "src/utils/queryResult";
import { PageHeader } from "../layout/PageHeader";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { formatWithYear } from "src/utils/dates";
import { isAfter } from "date-fns";

export function FundAllocationsPage() {
  useDocumentTitle("Lots");

  const lotsQuery = useFundAllocationsPageLotsQuery({ variables: {} });
  const fundQuery = useFundAllocationsPageFundsQuery({ variables: {} });
  const funds = fundQuery.data?.funds || [];

  return queryResult(lotsQuery, {
    data: (data) => {
      const { lots } = data;
      // We should really do this filter in the query, but this is easist for now to get it working - we only need to allocate (or show) lots acquired after 2024
      const recentLots = lots
        .filter((lot) => lot.underContractDate && isAfter(lot.underContractDate, new Date("2024-12-31")))
        .sortBy((lot) => lot.id || "")
        .reverse();
      const title = `Lots for Allocation to Funds`;
      return (
        <>
          <PageHeader title={title} />
          <ScrollableContent>
            <div css={Css.df.py2.aifs.jcsb.$} data-testid="drawOverviewPage">
              <div css={Css.fg1.$}>
                <FundAllocationsTable lots={recentLots} funds={funds} />
              </div>
            </div>
          </ScrollableContent>
        </>
      );
    },
  });
}

function FundAllocationsTable(props: {
  lots: FundAllocationsPageLotFragment[];
  funds: FundAllocationsPageFundFragment[];
}) {
  const { lots, funds } = props;
  const [saveLotsMutation] = useSaveLotsMutation();
  const [sendToBlueprintMutation] = useSendCustomerInformationToBlueprintMutation();

  async function saveLots(formState: ObjectState<FormInput>) {
    const changedLots =
      formState.changedValue.lots?.filter((lot) => lot.fundId !== undefined || lot.fundOwnerEntityId !== undefined) ||
      [];
    await saveLotsMutation({
      variables: { input: { lots: changedLots } },
    });
  }

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      lots: {
        type: "list",
        config: lotConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: lots,
      map: mapToForm,
    },
    autoSave: saveLots,
  });

  const columns = createColumns(formState, funds, sendToBlueprintMutation);
  const rows = mapToRows(lots);

  return (
    <GridTable
      id="FundAllocationsTable"
      columns={columns}
      rows={rows}
      rowStyles={rowStyles}
      // sorting={{ on: "client" }}
      stickyHeader
      style={{ rowHeight: "flexible" }}
    />
  );
}

type Row = SimpleHeaderAndData<FundAllocationsPageLotFragment>;

function createColumns(
  formState: ObjectState<FormInput>,
  funds: FundAllocationsPageFundFragment[],
  sendToBlueprintMutation: (arg0: { variables: { input: { lotId: string } } }) => any,
): GridColumn<Row>[] {
  const idColumn = column<Row>({ header: "ID", data: (row) => row.id, w: "72px" });
  const streetColumn = column<Row>({ header: "Address", data: (row) => row.address?.street1, w: "200px" });
  const cityColumn = column<Row>({ header: "City", data: (row) => row.address?.city, w: "100px" });
  const underContractDateColumn = column<Row>({
    header: "UC Date",
    data: (row) => (row.underContractDate ? formatWithYear(row.underContractDate) : "-"),
    w: "150px",
  });

  const investmentStatusColumn = column<Row>({
    header: "Investment Status",
    data: (row) => row.lotInvestmentStatus.name,
    w: "100px",
  });

  const fundColumn = column<Row>({
    header: "Fund",
    data: (row) => {
      const formStateField = formState.lots.rows.find((li) => li.id.value === row.id);
      const thisColumn = {
        value: () => row.fund?.name,
        content: () => (
          <BoundSelectField
            field={formStateField!.fundId}
            options={funds.map((f) => [f.name, f.id])}
            getOptionValue={([, id]) => id}
            getOptionLabel={([name]) => name}
            readOnly={!row.canEditFund.allowed}
            onSelect={(fundId) => {
              // If the fund ID that is chosen no longer works with the owner entity, clear the owner entity
              const fund = funds.find((f) => f.id === fundId);
              if (fund && !fund.ownerEntities.some((oe) => oe.id === row.fundOwnerEntity?.id)) {
                formStateField!.fundOwnerEntityId.set(null);
              }
              formStateField!.fundId.set(fundId);
            }}
          />
        ),
      };
      return thisColumn;
    },
    w: "200px",
  });

  const owningLegalEntityColumn = column<Row>({
    header: "Owning Legal Entity",
    data: (row) => {
      const formStateField = formState.lots.rows.find((li) => li.id.value === row.id);
      const thisFund = funds.find((f) => f.id === row.fund?.id);
      const availableFundOwnerEntities = thisFund?.ownerEntities || [];
      const thisColumn = {
        value: () => row.fundOwnerEntity?.name,
        content: () => (
          <BoundSelectField
            field={formStateField!.fundOwnerEntityId}
            options={availableFundOwnerEntities.map((f) => [f.name, f.id])}
            getOptionValue={([, id]) => id}
            getOptionLabel={([name]) => name}
            readOnly={!row.canEditFund.allowed}
          />
        ),
      };
      return thisColumn;
    },
    w: "300px",
  });

  const sendColumn = column<Row>({
    header: "Send to Blueprint",
    data: (row) => (
      <Button
        label="Send"
        onClick={async () => {
          await sendToBlueprintMutation({ variables: { input: { lotId: row.id } } });
          return;
        }}
        disabled={!row.canSendCustomerInformationToBlueprint.allowed}
        tooltip={
          row.canSendCustomerInformationToBlueprint.allowed
            ? "Send Customer Information to Blueprint"
            : row.canSendCustomerInformationToBlueprint.disabledReasons.map((r) => r.message).join("; ")
        }
      />
    ),
  });

  return [
    idColumn,
    streetColumn,
    cityColumn,
    underContractDateColumn,
    investmentStatusColumn,
    fundColumn,
    owningLegalEntityColumn,
    sendColumn,
  ];
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function mapToRows(lots: FundAllocationsPageLotFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lots);
}

type FormInput = SaveLotsInput & {
  lots: Maybe<SaveLotInput[]>;
};

export const lotConfig: ObjectConfig<SaveLotInput> = {
  id: { type: "value" },
  fundId: { type: "value" },
  fundOwnerEntityId: { type: "value" },
};

function mapToForm(lots: FundAllocationsPageLotFragment[]): FormInput {
  return {
    lots: mapLotsToForm(lots),
  };
}

function mapLotsToForm(lots: FundAllocationsPageLotFragment[]): SaveLotInput[] {
  return lots.map((lot) => {
    return {
      id: lot.id,
      fundId: lot.fund?.id,
      fundOwnerEntityId: lot.fundOwnerEntity?.id,
    };
  });
}

import { BoundNumberField, IconButton, TextField } from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useHistory, useParams } from "react-router";
import { PdfViewer } from "src/components/PdfViewer";
import {
  AssetInfoFragment,
  InputMaybe,
  LotAcqLotAcqPurchaseAgreementFragment,
  LotAcqLotFragment,
  SaveLotAcqPurchaseAgreementInput,
  useDeleteLotAcqPurchaseAgreementMutation,
  useLotAcqPurchaseAgreementDocumentsQuery,
  useSaveLotAcqPurchaseAgreementMutation,
} from "src/generated/graphql-types";
import { PageHeader } from "src/routes/layout/PageHeader";
import { DocumentIdParams } from "src/routes/routesDef";
import { createLotDetailsUrl } from "src/RouteUrls";
import { queryResult } from "src/utils/queryResult";

export function LotAcqPurchaseAgreementEditor() {
  const { documentId } = useParams<DocumentIdParams>();

  const documentsQuery = useLotAcqPurchaseAgreementDocumentsQuery({
    variables: { documentIds: [documentId] },
    skip: !documentId,
  });

  return queryResult(documentsQuery, (data) => {
    const document = data.documents[0];
    const lot = document.lot;
    const documentId = document.id;
    const lapa = document.lotAcqPurchaseAgreement;
    return (
      <LotAcqPurchaseAgreementForm
        documentId={documentId}
        lot={lot}
        assetInfo={document.asset}
        lotAcqPurchaseAgreement={lapa!}
      />
    );
  });
}

type LotAcqPurchaseAgreementEditorProps = {
  documentId: string;
  lot: LotAcqLotFragment;
  assetInfo: AssetInfoFragment;
  lotAcqPurchaseAgreement: LotAcqLotAcqPurchaseAgreementFragment;
};

function LotAcqPurchaseAgreementForm({
  documentId,
  lot,
  assetInfo,
  lotAcqPurchaseAgreement,
}: LotAcqPurchaseAgreementEditorProps) {
  const [saveLotAcqPurchaseAgreement] = useSaveLotAcqPurchaseAgreementMutation();
  const [deleteLotAcqPurchaseAgreement] = useDeleteLotAcqPurchaseAgreementMutation();
  const history = useHistory();

  async function saveLotAcqPurchaseAgreementFunction(formState: ObjectState<LotAcqPurchaseAgreementFormInput>) {
    await saveLotAcqPurchaseAgreement({
      variables: {
        input: mapToInput(formState.value),
      },
    });
  }

  const formState = useFormState({
    config: formConfig,
    init: {
      input: mapPurchaseAgreementToForm(lotAcqPurchaseAgreement),
    },
    autoSave: saveLotAcqPurchaseAgreementFunction,
  });

  function mapToInput(formStateValue: LotAcqPurchaseAgreementFormInput): SaveLotAcqPurchaseAgreementInput {
    const { documentId, lotId, ...others } = formStateValue;
    return {
      ...others,
      documentId,
      lotId,
    };
  }

  const title = "Edit Settlement Statement for " + lot?.address?.street1 + " (" + lot?.id + ")";

  return (
    <>
      <PageHeader title={title} />
      <TextField
        label="Investment Status"
        value={lotAcqPurchaseAgreement?.lot.lotInvestmentStatus.name}
        readOnly
        onChange={() => {}}
      />
      <BoundNumberField
        field={formState.purchasePriceInCents}
        label="Purchase Price (from this document)"
        type="cents"
      />
      <BoundNumberField field={formState.earnestMoneyInCents} label="Earnest Money" type="cents" />
      <BoundNumberField field={formState.optionFeeInCents} label="Option Fee" type="cents" />
      <IconButton
        icon="trash"
        tooltip="delete Lot Acq Purchase Agreement"
        onClick={async () => {
          await deleteLotAcqPurchaseAgreement({
            variables: {
              input: { id: lotAcqPurchaseAgreement.id },
            },
          });
          history.push({ pathname: createLotDetailsUrl(lotAcqPurchaseAgreement.lot.id) });
        }}
      />
      <PdfViewer
        hasHeader
        title={"Uploaded Purchase Agreement PDF"}
        assets={[assetInfo]}
        pdfPageWidth={getDynamicPdfVierwerWidth()}
      />
    </>
  );
}

export type LotAcqPurchaseAgreementFormInput = {
  id: InputMaybe<string>;
  lotId: InputMaybe<string>;
  documentId: InputMaybe<string>;
  purchasePriceInCents: InputMaybe<number>;
  earnestMoneyInCents: InputMaybe<number>;
  optionFeeInCents: InputMaybe<number>;
};

export const formConfig: ObjectConfig<LotAcqPurchaseAgreementFormInput> = {
  id: { type: "value" },
  lotId: { type: "value", rules: [required] },
  documentId: { type: "value", rules: [required] },
  purchasePriceInCents: { type: "value", rules: [required] },
  earnestMoneyInCents: { type: "value", rules: [required] },
  optionFeeInCents: { type: "value", rules: [required] },
};

function mapPurchaseAgreementToForm(
  purchaseAgreement: LotAcqLotAcqPurchaseAgreementFragment,
): LotAcqPurchaseAgreementFormInput {
  return {
    id: purchaseAgreement.id,
    lotId: purchaseAgreement.lot.id,
    documentId: purchaseAgreement.document.id,
    purchasePriceInCents: purchaseAgreement.purchasePriceInCents,
    earnestMoneyInCents: purchaseAgreement.earnestMoneyInCents,
    optionFeeInCents: purchaseAgreement.optionFeeInCents,
  };
}

export function getDynamicPdfVierwerWidth(browserWidth?: number) {
  const { innerHeight } = window;
  return innerHeight > 900 ? 820 : innerHeight > 800 ? 760 : 640;
}

export const idOrAdd = ":idOrAdd";
export type IdOrAddParams = { idOrAdd: string };
export type DocumentIdParams = { documentId: string };
export type LotIdParams = { lotId: string };

export const lotsPath = "/lots";
export const lotPath = "/lotDetails/:lotId(l:\\d*)";

export const fundsPath = "/funds";
export const fundPath = "/fundDetails/:fundId(fund:\\d*)";
export const fundLoanManagementPath = "/fundLoanManagement/:fundId(fund:\\d*)";

export const drawPath = "/drawDetails/:drawId(cfd:\\d*)";
export const drawRequestPath = "/drawRequestDetails/:drawRequestId(cfdr:\\d*)";

export const paymentPath = "/paymentDetails/:paymentId(cfp:\\d*)";

export const invoicePath = "/invoiceDetails/:invoiceId(cfi:\\d*)";

export const metrosPath = "/metros";

export const lotAcqSettlementStatementPath = `/lotAcqSettlementStatement/:documentId(d:\\d*)`;
export const lotAcqPurchaseAgreementPath = `/lotAcqPurchaseAgrement/:documentId(d:\\d*)`;

export const fundAllocationsPath = "/fundAllocations";

export const underwritingDefaultsPath = "/underwritingDefaults";

import { BoundNumberField, Css } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  SaveUnderwritingConfigInput,
  UnderwritingOverviewPageUnderwritingConfigFragment,
  useSaveUnderwritingConfigMutation,
  useUnderwritingOverviewUnderwritingConfigQuery,
} from "src/generated/graphql-types";

type UnderwritingDetailsProps = {
  underwritingConfig: UnderwritingOverviewPageUnderwritingConfigFragment;
};

export type SaveUnderwritingDetails = SaveUnderwritingConfigInput;

export function UnderwritingDetails({ underwritingConfig }: UnderwritingDetailsProps) {
  const query = useUnderwritingOverviewUnderwritingConfigQuery({
    variables: { underwritingConfigId: underwritingConfig.id },
  });
  const [saveUnderwritingMutation] = useSaveUnderwritingConfigMutation();

  const formConfig: ObjectConfig<SaveUnderwritingDetails> = useMemo(
    () => ({
      id: { type: "value" },
      targetInvestorMarginBasisPoints: { type: "value", rules: [required] },
      debtFacilityBaseRateBasisPoints: { type: "value", rules: [required] },
      debtFacilitySpreadBasisPoints: { type: "value", rules: [required] },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      query,
      map: ({ underwritingConfigs: [underwritingConfig] }) => ({
        ...underwritingConfig,
      }),
    },
    autoSave: saveUnderwriting,
  });

  async function saveUnderwriting() {
    await saveUnderwritingMutation({
      variables: { input: formState.value },
    });
  }

  return (
    <div css={Css.df.aic.$}>
      <div css={Css.df.wPx(400).fs0.fdc.p2.bgGray100.$}>
        <div css={Css.df.fdc.gap3.$}>
          <BoundNumberField
            field={formState.targetInvestorMarginBasisPoints}
            type="basisPoints"
            label="Default Target Investor Margin"
          />
          <BoundNumberField
            field={formState.debtFacilityBaseRateBasisPoints}
            type="basisPoints"
            label="Default Base Rate"
          />
          <BoundNumberField
            field={formState.debtFacilitySpreadBasisPoints}
            type="basisPoints"
            label="Default Spread Rate"
          />
        </div>
      </div>
    </div>
  );
}

import { TabsWithContent } from "@homebound/beam";
import { useState } from "react";
import { UnderwritingOverviewPageUnderwritingConfigFragment } from "src/generated/graphql-types";
import { UnderwritingDetails } from "./UnderwritingDetails";
import { UnderwritingMetrosTable } from "./UnderwritingMetrosTable";

export type UnderwritingOverviewTabsProps = {
  underwritingConfig: UnderwritingOverviewPageUnderwritingConfigFragment;
};

export function UnderwritingOverviewTabs({ underwritingConfig }: UnderwritingOverviewTabsProps) {
  const [currentTab, setCurrentTab] = useState("UnderwritingDetails");

  const tabs = getTabs({ underwritingConfig });

  return <TabsWithContent selected={currentTab} onChange={setCurrentTab} tabs={tabs} />;
}

function getTabs(props: UnderwritingOverviewTabsProps) {
  const { underwritingConfig } = props;

  return [
    {
      name: "Underwriting Details",
      value: "UnderwritingDetails",
      render: () => <UnderwritingDetails underwritingConfig={underwritingConfig} />,
    },
    {
      name: "Underwriting Metros",
      value: "UnderwritingMetros",
      render: () => (
        <UnderwritingMetrosTable underwritingConfig={underwritingConfig} metros={underwritingConfig.metros} />
      ),
    },
  ];
}

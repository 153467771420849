import { generatePath } from "react-router-dom";
import {
  drawPath,
  drawRequestPath,
  fundLoanManagementPath,
  fundPath,
  invoicePath,
  lotAcqPurchaseAgreementPath,
  lotAcqSettlementStatementPath,
  lotPath,
  paymentPath,
} from "./routes/routesDef";

/** Present in place of an ID in URLs when creating items on CRUD pages */
export const addEntityParam = "add";

export function createLotDetailsUrl(lotId: string) {
  return generatePath(lotPath, { lotId });
}

export function createFundDetailsUrl(fundId: string) {
  return generatePath(fundPath, { fundId });
}

export function createFundLoanManagementUrl(fundId: string) {
  return generatePath(fundLoanManagementPath, { fundId });
}

export function createDrawDetailsUrl(drawId: string) {
  return generatePath(drawPath, { drawId });
}

export function createDrawRequestDetailsUrl(drawRequestId: string) {
  return generatePath(drawRequestPath, { drawRequestId });
}

export function createPaymentDetailsUrl(paymentId: string) {
  return generatePath(paymentPath, { paymentId });
}

export function createInvoiceDetailsUrl(invoiceId: string) {
  return generatePath(invoicePath, { invoiceId });
}

export function createLotAcqSettlementStatementUrl(documentId: string) {
  return generatePath(lotAcqSettlementStatementPath, { documentId });
}

export function createLotAcqPurchaseAgreementUrl(documentId: string) {
  return generatePath(lotAcqPurchaseAgreementPath, { documentId });
}

import {
  BoundNumberField,
  column,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useMemo } from "react";
import {
  Maybe,
  SaveUnderwritingConfigMetroInput,
  UnderwritingOverviewPageUnderwritingConfigFragment,
  UnderwritingOverviewPageUnderwritingConfigMetroFragment,
  useSaveUnderwritingConfigMetroMutation,
} from "src/generated/graphql-types";

export type UnderwritingMetrosTableProps = {
  metros: UnderwritingOverviewPageUnderwritingConfigMetroFragment[];
  underwritingConfig: UnderwritingOverviewPageUnderwritingConfigFragment;
};

export type SaveUnderwritingMetroDetails = SaveUnderwritingConfigMetroInput;

export function UnderwritingMetrosTable(props: UnderwritingMetrosTableProps) {
  const { metros, underwritingConfig } = props;

  const [saveUnderwritingMetroMutation] = useSaveUnderwritingConfigMetroMutation();

  const formConfigMetro: ObjectConfig<FormInput> = useMemo(
    () => ({
      underwritingConfigMetros: {
        type: "list",
        config: underwritingConfigMetroConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfigMetro,
    init: {
      input: underwritingConfig,
      map: mapToMetroForm,
    },
    autoSave: saveUnderwritingMetros,
  });

  async function saveUnderwritingMetros(formState: ObjectState<FormInput>) {
    const changedUnderwritingMetros =
      formState.changedValue.underwritingConfigMetros?.filter(
        (fm) => fm.targetInvestorMarginBasisPoints !== undefined,
      ) || [];
    await Promise.all(
      changedUnderwritingMetros.map(async (fm) =>
        saveUnderwritingMetroMutation({
          variables: { input: { ...fm } },
        }),
      ),
    );
  }

  return (
    <>
      <GridTable
        id="underwritingConfigMetrosTable"
        rowStyles={rowStyles}
        columns={createUnderwritingMetroColumns(metros, formState)}
        rows={createUnderwritingMetroRows(metros)}
        fallbackMessage="Underwriting Metros for this Underwriting will show here."
      />
    </>
  );
}

type Row = SimpleHeaderAndData<UnderwritingOverviewPageUnderwritingConfigMetroFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createUnderwritingMetroRows(
  metros: UnderwritingOverviewPageUnderwritingConfigMetroFragment[],
): GridDataRow<Row>[] {
  return simpleDataRows(metros);
}

function createUnderwritingMetroColumns(
  metros: UnderwritingOverviewPageUnderwritingConfigMetroFragment[],
  formState: ObjectState<FormInput>,
): GridColumn<Row>[] {
  const underwritingConfigMetroIdColumn = column<Row>({
    header: () => "Id",
    data: ({ id }) => id,
  });

  const metroNameColumn = column<Row>({
    header: () => "Metro",
    data: ({ metro }) => metro.name,
  });

  const targetInvestorMarginColumn = column<Row>({
    header: () => "Target Investor Margin",
    data: (row) => {
      const formStateField = formState.underwritingConfigMetros.rows.find((fm) => fm.id.value === row.id);
      return {
        value: () => row.targetInvestorMarginBasisPoints,
        content: () => <BoundNumberField type="basisPoints" field={formStateField!.targetInvestorMarginBasisPoints} />,
      };
    },
  });

  return [underwritingConfigMetroIdColumn, metroNameColumn, targetInvestorMarginColumn];
}

function mapToMetroForm(underwritingConfig: UnderwritingOverviewPageUnderwritingConfigFragment): FormInput {
  return {
    id: underwritingConfig.id,
    underwritingConfigMetros: mapUnderwritingMetrosToForm(underwritingConfig.metros),
  };
}

export function mapUnderwritingMetrosToForm(
  underwritingConfigMetros: Maybe<UnderwritingOverviewPageUnderwritingConfigMetroFragment[]>,
) {
  return underwritingConfigMetros?.map((fm) => {
    return {
      ...fm,
    };
  });
}

type FormInput = SaveUnderwritingMetroDetails & {
  underwritingConfigMetros: Maybe<SaveUnderwritingMetroDetails[]>;
};

export const underwritingConfigMetroConfig: ObjectConfig<SaveUnderwritingMetroDetails> = {
  id: { type: "value" },
  targetInvestorMarginBasisPoints: { type: "value", rules: [required] },
};

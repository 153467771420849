import {
  BoundNumberField,
  Button,
  column,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  numericColumn,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useGridTableApi,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, useFormState } from "@homebound/form-state";
import { format } from "date-fns";
import { useMemo } from "react";
import { percentCellContent, priceCellContent } from "src/components";
import {
  DrawRequestOverviewPageDrawRequestFragment,
  DrawRequestOverviewPageLotPartitionDrawRequestFragment,
  Maybe,
  SaveCreditFacilityDrawRequestInput,
  SaveLotPartitionDrawRequestInput,
  useDrawRequestOverviewDrawRequestQuery,
  useRefreshAnchorMultilotDrawModelBlueprintDataMutation,
  useRefreshGoldmanDrawModelBlueprintDataMutation,
  useSaveLotPartitionDrawRequestsMutation,
} from "src/generated/graphql-types";
import { queryResult } from "src/utils/queryResult";
import { wait } from "src/utils/utils";

type DrawRequestLotPartitionDrawRequestsTableProps = {
  drawRequest: DrawRequestOverviewPageDrawRequestFragment;
};

export type SaveDrawRequestDetails = SaveCreditFacilityDrawRequestInput;

export function DrawRequestLotPartitionDrawRequestsTable(props: DrawRequestLotPartitionDrawRequestsTableProps) {
  const { drawRequest } = props;

  const query = useDrawRequestOverviewDrawRequestQuery({ variables: { drawRequestId: drawRequest.id } });

  return queryResult(query, {
    data: (data) => {
      const drawRequest = data.creditFacilityDrawRequests[0];
      return <LotPartitionDrawRequestDataView drawRequest={drawRequest} />;
    },
  });
}

function LotPartitionDrawRequestDataView({ drawRequest }: { drawRequest: DrawRequestOverviewPageDrawRequestFragment }) {
  const [saveLotPartitionDrawRequestsMutation] = useSaveLotPartitionDrawRequestsMutation();

  async function saveLotPartitionDrawRequests(formState: ObjectState<FormInput>) {
    const changedLotPartitionDrawRequests =
      formState.changedValue.lotPartitionDrawRequests?.filter((lpd) => lpd.amountInCents !== undefined) || [];
    await saveLotPartitionDrawRequestsMutation({
      variables: { input: { lotPartitionDrawRequests: changedLotPartitionDrawRequests } },
    });
  }

  const formConfig: ObjectConfig<FormInput> = useMemo(
    () => ({
      id: { type: "value" },
      lotPartitionDrawRequests: {
        type: "list",
        config: lotPartitionDrawRequestConfig,
      },
    }),
    [],
  );

  const formState = useFormState({
    config: formConfig,
    init: {
      input: drawRequest,
      map: mapToForm,
    },
    autoSave: saveLotPartitionDrawRequests,
  });

  const [refreshGSDrawBPData] = useRefreshGoldmanDrawModelBlueprintDataMutation();
  const [refreshAnchorDrawBPData] = useRefreshAnchorMultilotDrawModelBlueprintDataMutation();

  // It's not entirely clear to me why this polling query needs to be separate from the main one, but the data isn't getting refreshed when trying to use the same object
  const pollingQuery = useDrawRequestOverviewDrawRequestQuery({ variables: { drawRequestId: drawRequest.id } });

  const lotPartitionDrawRequests = drawRequest.lotPartitionDrawRequests;
  const isGoldmanDrawModel = lotPartitionDrawRequests[0].goldmanDrawModel?.id !== undefined; // Either they will all be there or none will, so just check the first one
  const isAnchorMultilotDrawModel = lotPartitionDrawRequests[0].anchorMultilotDrawModel?.id !== undefined; // Either they will all be there or none will, so just check the first one

  const columns = createColumns(
    lotPartitionDrawRequests,
    formState,
    !drawRequest.canEdit.allowed,
    isGoldmanDrawModel,
    isAnchorMultilotDrawModel,
  );
  const rows = mapToRows(lotPartitionDrawRequests);

  const goldmanDrawModelIds = lotPartitionDrawRequests.map((lpd) => lpd.goldmanDrawModel?.id).compact();
  const anchorDrawModelIds = lotPartitionDrawRequests.map((lpd) => lpd.anchorMultilotDrawModel?.id).compact();

  const tableApi = useGridTableApi<Row>();

  return (
    <>
      <GridTable
        id="drawRequestLotPartitionDrawRequestsTable"
        columns={columns}
        rows={rows}
        rowStyles={rowStyles}
        sorting={{ on: "client" }}
        stickyHeader
        style={{ rowHeight: "flexible" }}
        api={tableApi}
      />
      <Button
        label="Refresh Blueprint Data"
        variant="secondary"
        onClick={async () => {
          if (isGoldmanDrawModel) {
            // This now triggers a refresh async
            await refreshGSDrawBPData({
              variables: { input: { goldmanDrawModelId: goldmanDrawModelIds } },
            });
            console.log(
              "Triggered Refresh for  Blueprint Data for Goldman Draw Models with IDs: ",
              goldmanDrawModelIds,
            );
            let refreshed = false;
            while (!refreshed) {
              await wait(5); // Wait 5 seconds between each poll
              console.log("Polling for Blueprint Data Refresh");
              const newDrawRequests = (await pollingQuery.refetch()).data?.creditFacilityDrawRequests || [];
              const refreshedLotPartitionDraw = newDrawRequests[0].lotPartitionDrawRequests;

              console.log(
                "isRefreshed: ",
                refreshedLotPartitionDraw.map((lpd) => lpd.goldmanDrawModel?.isBlueprintCostDataSet),
              );
              refreshed = refreshedLotPartitionDraw.every((lpd) => lpd.goldmanDrawModel?.isBlueprintCostDataSet);
            }
            // await query.refetch();
            console.log("Refreshed Blueprint Data for Goldman Draw Models");
          } else if (isAnchorMultilotDrawModel) {
            // This now triggers a refresh async
            await refreshAnchorDrawBPData({
              variables: { input: { drawModelId: anchorDrawModelIds } },
            });
            console.log(
              "Triggered Refresh for  Blueprint Data for Anchor Multilot Draw Models with IDs: ",
              anchorDrawModelIds,
            );
            let refreshed = false;
            while (!refreshed) {
              await wait(5); // Wait 5 seconds between each poll
              console.log("Polling for Blueprint Data Refresh");
              const newDrawRequests = (await pollingQuery.refetch()).data?.creditFacilityDrawRequests || [];
              const refreshedLotPartitionDraw = newDrawRequests[0].lotPartitionDrawRequests;

              console.log(
                "isRefreshed: ",
                refreshedLotPartitionDraw.map((lpd) => lpd.anchorMultilotDrawModel?.isBlueprintCostDataSet),
              );
              refreshed = refreshedLotPartitionDraw.every((lpd) => lpd.anchorMultilotDrawModel?.isBlueprintCostDataSet);
            }
            console.log("Refreshed Blueprint Data for Anchor Draw Models");
          }
        }}
        disabled={(!isGoldmanDrawModel && !isAnchorMultilotDrawModel) || !drawRequest.canEdit.allowed}
        tooltip={drawRequest.canEdit.disabledReasons.map((reason) => reason.message).join(", ")}
      />
      <Button
        label="Refresh Draw Request Amount"
        variant="secondary"
        onClick={async () => {
          formState.lotPartitionDrawRequests.rows.forEach((row) => {
            const lpdr = lotPartitionDrawRequests.find((basicRow) => basicRow.id === row.id.value);
            if (lpdr) {
              row.amountInCents.set(
                lpdr.modeledCollectiveAmountToBorrowInCents - lpdr.lotPartition.borrowedAmountInCents,
              );
            }
          });
        }}
        disabled={(!isGoldmanDrawModel && !isAnchorMultilotDrawModel) || !drawRequest.canEdit.allowed}
      />
      <Button
        onClick={() =>
          tableApi.downloadToCsv(
            drawRequest.fund.name + "_DrawRequest_" + format(drawRequest.preparationDate, "ddMMMyy") + ".csv",
          )
        }
        label="Download CSV"
      />
    </>
  );
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

type Row = SimpleHeaderAndData<DrawRequestOverviewPageLotPartitionDrawRequestFragment>;

function createColumns(
  lotPartitionDrawRequests: DrawRequestOverviewPageLotPartitionDrawRequestFragment[],
  formState: ObjectState<FormInput>,
  isReadOnly: boolean,
  isGoldmanDrawModel: boolean,
  isAnchorMultilotDrawModel: boolean,
): GridColumn<Row>[] {
  const lotPartitionIdColumn = column<Row>({
    header: "Lot Partition",
    data: (row) => row.lotPartition.id,
    w: "240px",
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: "Address",
    data: (row) => row.lotPartition.lot.address?.street1,
    w: "240px",
    sticky: "left",
  });

  const blueprintProjectColumn = column<Row>({
    header: "Blueprint Project",
    data: (row) => row.lotPartition.blueprintProjectId,
    w: "240px",
    sticky: "left",
  });

  const drawAmountColumn = numericColumn<Row>({
    header: () => "Draw Request Amount",
    data: (row) => {
      const formStateField = formState.lotPartitionDrawRequests.rows.find((lpd) => lpd.id.value === row.id);
      return {
        value: () => row.amountInCents,
        content: () => <BoundNumberField type="cents" field={formStateField!.amountInCents} readOnly={isReadOnly} />,
      };
    },
    w: "140px",
  });

  const modelAmountColumn = numericColumn<Row>({
    header: () => "Model Amount (Individual)",
    data: (row) => amountGridCellContent(row.modeledIndividualAmountToBorrowInCents),
    w: "140px",
  });

  const modelCollectiveAmountColumn = numericColumn<Row>({
    header: () => "Model Amount (With Collective Adjustments)",
    data: (row) => amountGridCellContent(row.modeledCollectiveAmountToBorrowInCents),
    w: "140px",
  });

  const amountBorrowedColumn = numericColumn<Row>({
    header: () => "Amount Borrowed",
    data: (row) => amountGridCellContent(row.lotPartition.borrowedAmountInCents),
    w: "140px",
  });

  const columns = [
    lotPartitionIdColumn,
    blueprintProjectColumn,
    addressColumn,
    drawAmountColumn,
    modelAmountColumn,
    modelCollectiveAmountColumn,
    amountBorrowedColumn,
  ];

  if (isGoldmanDrawModel) {
    const goldmanColumns = getGoldmanColumns();
    columns.push(...goldmanColumns);
  }

  if (isAnchorMultilotDrawModel) {
    const anchorColumns = getAnchorColumns();
    columns.push(...anchorColumns);
  }

  return columns;
}

function getGoldmanColumns() {
  const isEligibleColumn = column<Row>({
    header: "Eligible",
    data: (row) => (row.goldmanDrawModel?.isEligibleForGoldmanDraw ? "Yes" : "No"),
    w: "100px",
  });

  const isTargetColumn = column<Row>({
    header: "isTargetBuy",
    data: (row) => (row.goldmanDrawModel?.isTargetBuy ? "Yes" : "No"),
    w: "100px",
  });

  const isExpandedColumn = column<Row>({
    header: "isExpandedBuy",
    data: (row) => (row.goldmanDrawModel?.isExpandedBuy ? "Yes" : "No"),
    w: "100px",
  });

  const isFinishedPropertyColumn = column<Row>({
    header: "isFinishedProperty",
    data: (row) => (row.goldmanDrawModel?.isFinishedProperty ? "Yes" : "No"),
    w: "100px",
  });

  const currentAppraisalAmountColumn = column<Row>({
    header: "Current Appraisal Amount",
    data: (row) => amountGridCellContent(row.goldmanDrawModel?.currentAppraisalValueInCents || 0),
    w: "100px",
  });

  const advanceRateColumn = column<Row>({
    header: "Advance Rate",
    data: (row) => amountGridCellContent((row.goldmanDrawModel?.advanceRateBasisPoints || 0) / 100, true),
    w: "100px",
  });

  const adjustedCollateralValueInCents = column<Row>({
    header: "Adjusted Collateral Value",
    data: (row) => amountGridCellContent(row.goldmanDrawModel?.adjustedCollateralValueInCents || 0),
    w: "100px",
  });

  const adjustedAdvanceRateColumn = column<Row>({
    header: "Adjusted Advance Rate",
    data: (row) => amountGridCellContent((row.goldmanDrawModel?.adjustedAdvanceRateBasisPoints || 0) / 100, true),
    w: "100px",
  });

  const realizedConstructionExpensesColumn = column<Row>({
    header: "Realized Construction Expenses (AU)",
    data: (row) => amountGridCellContent(row.goldmanDrawModel?.realizedConstructionExpensesInCents || 0),
    w: "100px",
  });

  const landCostsColumn = column<Row>({
    header: "Land Costs",
    data: (row) => amountGridCellContent(row.goldmanDrawModel?.landCostsInCents || 0),
    w: "100px",
  });

  const homeboundPortionInCentsColumn = column<Row>({
    header: "Homebound Portion",
    data: (row) => amountGridCellContent(row.goldmanDrawModel?.homeboundPortionInCents || 0),
    w: "100px",
  });

  const advanceRatePreconColumn = column<Row>({
    header: "Advance Rate (Precon)",
    data: (row) => amountGridCellContent((row.goldmanDrawModel?.advanceRatePreconBasisPoints || 0) / 100, true),
    w: "100px",
  });

  const underwrittenSalesPriceMarginColumn = column<Row>({
    header: "UW Sales Price Margin",
    data: (row) =>
      amountGridCellContent((row.goldmanDrawModel?.underwrittenSalesPriceMarginBasisPoints || 0) / 100, true),
    w: "100px",
  });

  const isVictoryOverrideColumn = column<Row>({
    header: "isVictoryOverride",
    data: (row) => (row.goldmanDrawModel?.isVictoryOverride ? "Yes" : "No"),
    w: "100px",
  });

  const isLotCostToUnderwrittenNetSalesPriceEligibleColumn = column<Row>({
    header: "isLotCostToUnderwrittenNetSalesPriceEligible",
    data: (row) => (row.goldmanDrawModel?.isLotCostToUnderwrittenNetSalesPriceEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isOwnedLessThan_721DaysColumn = column<Row>({
    header: "isOwnedLessThan_721Days",
    data: (row) => (row.goldmanDrawModel?.isOwnedLessThan_721Days ? "Yes" : "No"),
    w: "100px",
  });
  const isAppraisalAgeAcceptableColumn = column<Row>({
    header: "isAppraisalAgeAcceptable",
    data: (row) => (row.goldmanDrawModel?.isAppraisalAgeAcceptable ? "Yes" : "No"),
    w: "100px",
  });
  const isInfillOrDeveloperCommunityColumn = column<Row>({
    header: "isInfillOrDeveloperCommunity",
    data: (row) => (row.goldmanDrawModel?.isInfillOrDeveloperCommunity ? "Yes" : "No"),
    w: "100px",
  });
  const isLandCostEligibleColumn = column<Row>({
    header: "isLandCostEligible",
    data: (row) => (row.goldmanDrawModel?.isLandCostEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isUnderwrittenHardCostEligibleColumn = column<Row>({
    header: "isUnderwrittenHardCostEligible",
    data: (row) => (row.goldmanDrawModel?.isUnderwrittenHardCostEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isLandCostAndConstructionExpensesEligibleColumn = column<Row>({
    header: "isLandCostAndConstructionExpensesEligible",
    data: (row) => (row.goldmanDrawModel?.isLandCostAndConstructionExpensesEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isUnderwrittenMarginEligibleColumn = column<Row>({
    header: "isUnderwrittenMarginEligible",
    data: (row) => (row.goldmanDrawModel?.isUnderwrittenMarginEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isBedroomsEligibleColumn = column<Row>({
    header: "isBedroomsEligible",
    data: (row) => (row.goldmanDrawModel?.isBedroomsEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isSqftEligibleColumn = column<Row>({
    header: "isSqftEligible",
    data: (row) => (row.goldmanDrawModel?.isSqftEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isBathroomsEligibleColumn = column<Row>({
    header: "isBathroomsEligible",
    data: (row) => (row.goldmanDrawModel?.isBathroomsEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isLandCostTargetEligibleColumn = column<Row>({
    header: "isLandCostTargetEligible",
    data: (row) => (row.goldmanDrawModel?.isLandCostTargetEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isLotCostToUnderwrittenNetSalesPriceTargetEligibleColumn = column<Row>({
    header: "isLotCostToUnderwrittenNetSalesPriceTargetEligible",
    data: (row) => (row.goldmanDrawModel?.isLotCostToUnderwrittenNetSalesPriceTargetEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isConstructionExpensesTargetEligibleColumn = column<Row>({
    header: "isConstructionExpensesTargetEligible",
    data: (row) => (row.goldmanDrawModel?.isConstructionExpensesTargetEligible ? "Yes" : "No"),
    w: "100px",
  });
  const isLandCostAndConstructionExpensesTargetEligibleColumn = column<Row>({
    header: "isLandCostAndConstructionExpensesTargetEligible",
    data: (row) => (row.goldmanDrawModel?.isLandCostAndConstructionExpensesTargetEligible ? "Yes" : "No"),
    w: "100px",
  });

  return [
    isEligibleColumn,
    isTargetColumn,
    isExpandedColumn,
    isFinishedPropertyColumn,
    currentAppraisalAmountColumn,
    advanceRateColumn,
    adjustedCollateralValueInCents,
    adjustedAdvanceRateColumn,
    realizedConstructionExpensesColumn,
    landCostsColumn,
    homeboundPortionInCentsColumn,
    advanceRatePreconColumn,
    underwrittenSalesPriceMarginColumn,
    isVictoryOverrideColumn,
    isLotCostToUnderwrittenNetSalesPriceEligibleColumn,
    isOwnedLessThan_721DaysColumn,
    isAppraisalAgeAcceptableColumn,
    isInfillOrDeveloperCommunityColumn,
    isLandCostEligibleColumn,
    isUnderwrittenHardCostEligibleColumn,
    isLandCostAndConstructionExpensesEligibleColumn,
    isUnderwrittenMarginEligibleColumn,
    isBedroomsEligibleColumn,
    isSqftEligibleColumn,
    isBathroomsEligibleColumn,
    isLandCostTargetEligibleColumn,
    isLotCostToUnderwrittenNetSalesPriceTargetEligibleColumn,
    isConstructionExpensesTargetEligibleColumn,
    isLandCostAndConstructionExpensesTargetEligibleColumn,
  ];
}

function getAnchorColumns() {
  const softCostsColumn = column<Row>({
    header: "Realized Soft Costs",
    data: (row) => amountGridCellContent((row.anchorMultilotDrawModel?.realizedSoftCostsInCents || 0) / 100, false),
    w: "100px",
  });
  const directCostsColumn = column<Row>({
    header: "Realized Direct Costs",
    data: (row) => amountGridCellContent((row.anchorMultilotDrawModel?.realizedDirectCostsInCents || 0) / 100, false),
    w: "100px",
  });
  const indirectCostsColumn = column<Row>({
    header: "Realized Indirect Costs",
    data: (row) => amountGridCellContent((row.anchorMultilotDrawModel?.realizedIndirectCostsInCents || 0) / 100, false),
    w: "100px",
  });
  const builderFeeColumn = column<Row>({
    header: "Realized Indirect Costs",
    data: (row) => amountGridCellContent((row.anchorMultilotDrawModel?.realizedBuilderFeeInCents || 0) / 100, false),
    w: "100px",
  });
  const constructionCostsColumn = column<Row>({
    header: "Realized Construction Costs",
    data: (row) =>
      amountGridCellContent((row.anchorMultilotDrawModel?.realizedConstructionExpensesInCents || 0) / 100, false),
    w: "100px",
  });

  return [softCostsColumn, directCostsColumn, indirectCostsColumn, builderFeeColumn, constructionCostsColumn];
}

function amountGridCellContent(amount: number, usePercent = false): GridCellContent {
  return {
    alignment: "left",
    content: usePercent ? percentCellContent(amount) : priceCellContent(amount),
    value: amount,
  };
}

function mapToRows(
  lotPartitionDrawRequests: DrawRequestOverviewPageLotPartitionDrawRequestFragment[],
): GridDataRow<Row>[] {
  return simpleDataRows(lotPartitionDrawRequests);
}

type FormInput = SaveCreditFacilityDrawRequestInput & {
  lotPartitionDrawRequests: Maybe<SaveLotPartitionDrawRequestInput[]>;
};

function mapToForm(creditFacilityDrawRequest: DrawRequestOverviewPageDrawRequestFragment): FormInput {
  return {
    id: creditFacilityDrawRequest.id,
    lotPartitionDrawRequests: mapLotPartitionDrawRequestsToForm(creditFacilityDrawRequest.lotPartitionDrawRequests),
  };
}

export function mapLotPartitionDrawRequestsToForm(
  lotPartitionDrawRequests: Maybe<DrawRequestOverviewPageLotPartitionDrawRequestFragment[]>,
) {
  return lotPartitionDrawRequests?.map((lpd) => {
    return {
      ...lpd,
    };
  });
}

export const lotPartitionDrawRequestConfig: ObjectConfig<SaveLotPartitionDrawRequestInput> = {
  id: { type: "value" },
  amountInCents: { type: "value" },
};
